import {
  MultifarmProvider,
  Dashboard as MultifarmDashboard
} from '@multifarm/widget'

// Providers
import { useThemeContext } from '../providers/ThemeProvider'

// Confic
import { TREX_PROVIDER_ENV_STASH } from '../static/application.constants'

export const Dashboard = () => {
  const { theme, themeColors, lng } = useThemeContext()

  const Props = {
    token: process.env[TREX_PROVIDER_ENV_STASH],
    theme: theme,
    themeColors: themeColors,
    provider: theme,
    lng: lng,
    key: theme,
    technologiesUsedPlacement: 'none'
  }

  return (
    <div className="flex flex-col w-full">
      <div>
        <MultifarmProvider {...Props}>
          <MultifarmDashboard />
        </MultifarmProvider>
      </div>
    </div>
  )
}
