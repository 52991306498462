// Styles
import './ToggleSwitch.scss'

export const ToggleSwitch = ({ name, dataYes, dataNo, checked, onChange }) => {
  return (
    <div className="toggle-switch">
      <input
        type="checkbox"
        className="toggle-switch-checkbox"
        name={name}
        id={name}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <label className="toggle-switch-label" htmlFor={name}>
        <span
          className="toggle-switch-inner"
          data-yes={dataYes}
          data-no={dataNo}
        />
        <span className="toggle-switch-switch" />
      </label>
    </div>
  )
}
