import { useState } from 'react'

// Services
import { localStorage } from '../services/localStorage.service'

/**
 * Hook used to persist state in local storage.
 * @param {string} initialValue - Initial state value
 * @param {string} persistKey - Key under which the state should be persiseted.
 * @param {boolean} json - Should data be stored as json.
 * @returns
 */
export default function usePersistState(initialValue, persistKey, json) {
  const [state, setState] = useState(() =>
    localStorage.get(persistKey)
      ? json
        ? JSON.parse(localStorage.get(persistKey))
        : localStorage.get(persistKey)
      : initialValue
  )

  localStorage.set(persistKey, json ? JSON.stringify(state) : state)

  return [state, setState]
}
