export function applyRawStyles(name, css) {
  const t = document.createElement('style')
  t.id = `multifarm-css/${name}`
  t.innerHTML = css

  setTimeout(() => {
    document.head.appendChild(t)
  }, 0)
}

export function removeRawStyles(name) {
  document.getElementById(`multifarm-css/${name}`)?.remove()
}
