import { createContext, useContext } from 'react'

const ThemeContext = createContext({
  theme: 'refi',
  themeColors: 'light',
  lng: 'en'
})

export const ThemeProvider = ({ children, theme, themeColors, lng }) => {
  return (
    <ThemeContext.Provider
      value={{
        theme,
        themeColors,
        lng
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export function useThemeContext() {
  return useContext(ThemeContext)
}
