import { useEffect } from 'react'

// Providers
import { ThemeProvider } from './providers/ThemeProvider'

// Components
import { Dashboard } from './components/Dashboard'
import { ToggleSwitch } from './components/ToggleSwitch/ToggleSwitch'

// Hooks
import usePersistState from './hooks/usePersistState'

// Utils
import { applyRawStyles, removeRawStyles } from './utils/theme'

// Static
import { THEME_COLORS } from './static/application.constants'

// Styles
import './App.scss'
import refiCss from '!!raw-loader!@multifarm/widget/dist/refi.css'

function App() {
  const [themeColors, setThemeColors] = usePersistState(
    THEME_COLORS.LIGHT,
    'multifarm/themeColors'
  )

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.data.theme) {
        setThemeColors(e.data.themeColors)
      }
    })
  }, [])

  useEffect(() => {
    removeRawStyles('refi')
    applyRawStyles('refi', refiCss)
  }, [])

  useEffect(() => {
    document.body.style.fontFamily = rootStyle(themeColors).fontFamily
    document.body.style.color = rootStyle(themeColors).color
  })

  return (
    <ThemeProvider theme={'refi'} themeColors={themeColors} lng={'en'}>
      <div className="h-screen min-h-screen" style={rootStyle(themeColors)}>
        <div className={'w-screen min-h-screen'} style={bodyStyle(themeColors)}>
          <div className={'p-5'}>
            <ToggleSwitch
              name="theme-colours"
              dataYes={THEME_COLORS.LIGHT}
              dataNo={THEME_COLORS.DARK}
              checked={themeColors == THEME_COLORS.LIGHT}
              onChange={(checked) => {
                setThemeColors(checked ? THEME_COLORS.LIGHT : THEME_COLORS.DARK)
              }}
            />
            <Dashboard />
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App

function bodyStyle(themeColors) {
  if (themeColors === THEME_COLORS.DARK) {
    return {
      background:
        'linear-gradient(rgb(60, 24, 26) 0%, rgba(60, 24, 26, 0) 100%)',
      backgroundColor: '#161616'
    }
  }
  // Light theme
  return {
    background:
      'linear-gradient(180deg, #FFEAEA 0%, rgba(255, 234, 234, 0) 100%)',
    backgroundColor: '#fff'
  }
}

function rootStyle(themeColors) {
  if (themeColors === THEME_COLORS.DARK) {
    return {
      color: '#fff',
      fontFamily: 'Inter'
    }
  }
  // Light theme
  return {
    color: '#000',
    fontFamily: 'Inter'
  }
}
